
import anna from "@images/main/experts/anna.jpg";
import anna2 from "@images/main/experts/anna@2x.jpg";
import anna3 from "@images/main/experts/anna@3x.jpg";
import demina from "@images/main/experts/demina@2x.jpg";
import diana from "@images/main/experts/diana.jpg";
import diana2 from "@images/main/experts/diana@2x.jpg";
import diana3 from "@images/main/experts/diana@3x.jpg";
import ekaterina from "@images/main/experts/ekaterina.jpg";
import ekaterina2 from "@images/main/experts/ekaterina@2x.jpg";
import ekaterina3 from "@images/main/experts/ekaterina@3x.jpg";
import elena from "@images/main/experts/elena@2x.jpg";
import filey from "@images/main/experts/filey.jpg";
import filey2 from "@images/main/experts/filey@2x.jpg";
import filey3 from "@images/main/experts/filey@3x.jpg";
import irinSan from "@images/main/experts/irinSan.jpg";
import irinSan2 from "@images/main/experts/irinSan@2x.jpg";
import irinSan3 from "@images/main/experts/irinSan@3x.jpg";
import lilia from "@images/main/experts/lilia.jpg";
import lilia2 from "@images/main/experts/lilia@2x.jpg";
import lilia3 from "@images/main/experts/lilia@3x.jpg";
import lita from "@images/main/experts/lita.jpg";
import lita2 from "@images/main/experts/lita@2x.jpg";
import lita3 from "@images/main/experts/lita@3x.jpg";
import maevskaya from "@images/main/experts/maevskaya@2x.jpg";
import mich from "@images/main/experts/mich.jpg";
import mich2 from "@images/main/experts/mich@2x.jpg";
import mich3 from "@images/main/experts/mich@3x.jpg";
import prosvetova from "@images/main/experts/prosvetova@2x.jpg";
import shu from "@images/main/experts/shu.jpg";
import shu2 from "@images/main/experts/shu@2x.jpg";
import shu3 from "@images/main/experts/shu@3x.jpg";
import sofiya from "@images/main/experts/sofiya.jpg";
import winner from "@images/main/experts/winner@2x-min.jpg";

import { BulletBlockItem } from "@/components/common/bulletBlock/types";
import { CarouselItemType, ExpertShowcaseImageType, ShowcaseExpertType } from "@/components/common/showcaseBlock/types";
import { EXPERT_RATE_FOR_TEXTS } from "@/constants";

const isFFAllowFirstVideoSessionWithoutPaymentOn = process.env.GATSBY_ALLOW_FIRST_VIDEO_SESSION_WITHOUT_PAYMENT === "true";

export const PRIVILEGES: BulletBlockItem[] = [
  {
    id: "1",
    title: "Проверенные тарологи",
    description: "Рекомендуем экспертов,\u00a0к которым обратились бы сами",
  },
  {
    id: "2",
    title: "Безопасная оплата",
    description: "Только через сервис, без прямых переводов на карту экспертам",
  },
  {
    id: "3",
    title: "Конфиденциальность",
    description: "Мы не записываем сессии и даём возможность позвонить анонимно",
  },
  {
    id: "4",
    title: "Возврат денег",
    description: "Вернём деньги, если возникнут проблемы",
  },
];

export const MAIN_PAGE_SHOWCASE_CAROUSEL_ITEMS: CarouselItemType[] = [
  {
    id: "1",
    content: "Что меня ждёт в будущем?",
  },
  {
    id: "2",
    content: "Будем ли мы вместе?",
  },
  {
    id: "3",
    content: "Нужно ли менять работу?",
  },
  {
    id: "4",
    content: "Когда я встречу любовь?",
  },
];

export const MAIN_PAGE_SHOWCASE_LABELS = [
  {
    id: "1",
    content: "Консультация от 7 мин",
  },
  {
    id: "2",
    content: `Цена от\u00a0${EXPERT_RATE_FOR_TEXTS}\u00a0₽/мин`,
  },
];

export const MAIN_PAGE_SHOWCASE_SLANTED_LABELS = [
  {
    id: "1",
    content: isFFAllowFirstVideoSessionWithoutPaymentOn
      ? "1-я консультация"
      : "Первые 7 минут",
  },
  {
    id: "2",
    content: isFFAllowFirstVideoSessionWithoutPaymentOn
      ? "бесплатная"
      : "в подарок",
  },
];

export const MAIN_PAGE_SHOWCASE_EXPERT_DATA: ShowcaseExpertType[] = [{
  expertName: "София",
  linkUrl: `/expert/118`,
},
{
  expertName: "Лита Вэйн",
  linkUrl: `/expert/7`,
},
{
  expertName: "Анна Лесная",
  linkUrl: `/expert/105`,
},

{
  expertName: "Екатерина Каменева",
  linkUrl: `/expert/166`,
},

{
  expertName: "Саша Шу",
  linkUrl: `/expert/170`,
},
{
  expertName: "Михаил Пересторонин",
  linkUrl: `/expert/160`,
},
{
  expertName: "Лилия Батурина",
  linkUrl: `/expert/35`,
},
{
  expertName: "Екатерина Филей",
  linkUrl: `/expert/167`,
},
{
  expertName: "Диана Лурье",
  linkUrl: `/expert/121`,
},
{
  expertName: "Екатерина Дёмина",
  linkUrl: `/expert/184`,
},
{
  expertName: "Любовь",
  linkUrl: `/expert/182`,
},
{
  expertName: "Анастасия Маевская",
  linkUrl: `/expert/179`,
},
{
  expertName: "Елена",
  linkUrl: `/expert/164`,
},
{
  expertName: "Анастасия Виннер",
  linkUrl: `/expert/107`,
},
];

export const MAIN_PAGE_SHOWCASE_IMAGE_DATA: ExpertShowcaseImageType[] = [{
  oneX: sofiya,
  twoX: sofiya,
  threeX: sofiya,
}, {
  oneX: lita,
  twoX: lita2,
  threeX: lita3,
}, {
  oneX: anna,
  twoX: anna2,
  threeX: anna3,
}, {
  oneX: ekaterina,
  twoX: ekaterina2,
  threeX: ekaterina3,
},  {
  oneX: shu,
  twoX: shu2,
  threeX: shu3,
}, {
  oneX: mich,
  twoX: mich2,
  threeX: mich3,
}, {
  oneX: lilia,
  twoX: lilia2,
  threeX: lilia3,
}, {
  oneX: filey,
  twoX: filey2,
  threeX: filey3,
}, {
  oneX: diana,
  twoX: diana2,
  threeX: diana3,
}, {
  oneX: demina,
  twoX: demina,
  threeX: demina,
}, {
  oneX: prosvetova,
  twoX: prosvetova,
  threeX: prosvetova,
}, {
  oneX: maevskaya,
  twoX: maevskaya,
  threeX: maevskaya,
}, {
  oneX: elena,
  twoX: elena,
  threeX: elena,
}, {
  oneX: winner,
  twoX: winner,
  threeX: winner,
}
];
