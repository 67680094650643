import { EXPERT_RATE_FOR_TEXTS } from "@/constants";

import { StoryItemProps } from "./types";

export const MAIN_PAGE_STORY_ITEMS: StoryItemProps[] = [
  {
    user: {
      mobileImage: {
        onex: "/main/user1.jpg",
        twox: "/main/user1.jpg",
        threex: "/main/user1.jpg",
      },
      desktopImage: {
        onex: "/main/user1.jpg",
        twox: "/main/user1.jpg",
        threex: "/main/user1.jpg",
      },
      name: "Жанна Габуаж",
      subtitle: "51 год",
      sessionDuration: 20,
      price: 2400,
      result: "Почувствовала себя более свободной от рутины",
    },
    expert: {
      id: 21,
      mobileImage: {
        onex: "/main/new_expert_02",
        twox: "/main/new_expert_02",
        threex: "/main/new_expert_02",
      },
      desktopImage: {
        onex: "/main/new_expert_02",
        twox: "/main/new_expert_02",
        threex: "/main/new_expert_02",
      },
      name: "Лея",
      isFired: false,
      url: "/expert/31",
    },
    request: {
      title: `Запрос`,
      content: [
        `Я очень много работаю, а ещё погружаюсь в проблемы детей и иногда занимаюсь внуком. <span>Моя личная проблема — отсутствие как такового времени на саму себя.</span> Хочется уже, наконец, себя начать радовать, баловать и просто выйти из положения жертвы! Больше думать о себе, чем о других.`,
      ],
    },
    consultation: {
      title: `Консультация`,
      content: [
        `Я пошла со своим вопросом на Lunaro и выбрала Лею. Это очень внимательная девушка-таролог! Уже на первой консультации она помогла мне немного справиться с моей проблемой, <span>мы рассмотрели вопрос с разных углов.</span> Отмечу, что Лея делала расклад быстро и чётко, при этом говорила по делу и не тянула время. При этом мне не нравились обычные консультации психолога,очень хотелось душевного общения,а не формализованного. <span>У нас с Леей сложился доверительный и лёгкий диалог</span>, я общалась, как с давней подругой.`,
      ],
    },
    result: {
      title: `Результат`,
      content: [
        `Мы нашли корень моей проблемы, в чём заключается моя жертвенность и откуда она растёт. Когда долго живёшь по одному и тому сценарию, не всегда понимаешь, насколько простым может быть решение. <span>Лея на мои вопросы ответила, помогла разобраться в сложившейся ситуации и направила меня по нужному пути.</span> Разложила всё по полочкам с учётом времени, которое мы рассматривали. Я поняла, как мне сделать так, чтобы моя жизнь стала гармоничной, и при этом всё успевать. Благодарю за консультацию!`,
      ],
    },
  },
  {
    user: {
      mobileImage: {
        onex: "/main/user2.jpg",
        twox: "/main/user2.jpg",
        threex: "/main/user2.jpg",
      },
      desktopImage: {
        onex: "/main/user2.jpg",
        twox: "/main/user2.jpg",
        threex: "/main/user2.jpg",
      },
      name: "Анна Агамян",
      subtitle: "28 лет",
      sessionDuration: 35,
      price: 3850,
      result: "Нашла общий язык с мужем и предотвратила развод",
    },
    expert: {
      id: 2,
      mobileImage: {
        onex: "/main/expert4",
        twox: "/main/expert4",
        threex: "/main/expert4",
      },
      desktopImage: {
        onex: "/main/expert4",
        twox: "/main/expert4",
        threex: "/main/expert4",
      },
      name: "Лита Вейн",
      isFired: true,
      url: "/expert/7",
    },
    request: {
      title: `Запрос`,
      content: [
        `Около полугода назад мы с мужем хотели развестись: я всегда его ревновала и думала, что <span>он мне изменяет, но проверить это никак не могла.</span> Отношения были похожи на чемодан без ручки, мне было тяжело решиться на этот шаг.`,
      ],
    },
    consultation: {
      title: `Консультация`,
      content: [
        `Во время консультации Лита чётко дала мне понять, что измена всего лишь мои выдумки. <span>Сначала я, конечно же, не поверила, так как вообще позвонила утолить любопытство.</span> Тогда она дала мне консультацию с точки зрения психолога и показывала ситуацию на картах. Как психолог Лита мне дала несколько дельных рекомендаций и техник. Но я не поверила и не воспользовалась ими. Со временем всё становилось хуже, поэтому мы с Литой провели ещё пару консультаций, <span>после чего я наконец-то начала прислушиваться и делать так, как советуют карты и Лита.</span>`
      ],
    },
    result: {
      title: `Результат`,
      content: [
        `Признаю, что мне было тяжело идти на некоторые уступки для своего мужа, у меня была установка не идти никогда на первый шаг лишь потому, что я женщина. Но благодаря картам я посмотрела на себя со стороны и <span>поняла, почему в отношениях было так сложно.</span> Вероятно, я очень тревожный человек)))`,
        `В итоге мы с мужем нашли общий язык и начали слушать и понимать друг друга. Я поняла, что 100% гармония возможна усилиям обоих. <span>Брак получилось спасти.</span> Теперь в любой непонятной ситуации обращаюсь именно к Лите, у неё получилось меня убедить.`
      ],
    },
  },
  {
    user: {
      mobileImage: {
        onex: "/main/user3.jpg",
        twox: "/main/user3.jpg",
        threex: "/main/user3.jpg",
      },
      desktopImage: {
        onex: "/main/user3.jpg",
        twox: "/main/user3.jpg",
        threex: "/main/user3.jpg",
      },
      name: "Дмитрий Тиганов",
      subtitle: "35 лет",
      sessionDuration: 25,
      price: 3000,
      result: "Понял, что мешает ему расти в работе, и быстро поправил ситуацию",
    },
    expert: {
      id: 20,
      mobileImage: {
        onex: "/main/expert5",
        twox: "/main/expert5",
        threex: "/main/expert5",
      },
      desktopImage: {
        onex: "/main/expert5",
        twox: "/main/expert5",
        threex: "/main/expert5",
      },
      name: "Игорь",
      isFired: true,
      url: "/expert/106",
    },
    request: {
      title: `Запрос`,
      content: [
        `На работе всё было хорошо, но я захотел развиваться дальше и понять, <span>что мешает моему потенциалу раскрыться в полной мере.</span>`
      ],
    },
    consultation: {
      title: `Консультация`,
      content: [
        `Не буду описывать, что показали карты, но расшифровка от Игоря была просто ВАУ. Это можно сравнить с полноценным коучингом, но меня направили за 25 минут, а не месяцы болтовни ни о чём. Расскажу, что я внедрил сразу после расклада.`,
      ],
    },
    result: {
      title: `Результат`,
      content: [
        `<ul>
        <li>1. Стал убирать фатализм и устранять привычку преувеличивать проблемы и трудности.</li>
        <li>‍2. Решился нанять себе ассистента.</li>
<li>‍3. Начал работать более системно, по графику, откладывая «нехотелки».</li>
<li>‍<span>4. Закрыл сомнения по поводу, какое направление выбрать для дальнейшего развития в бизнесе.</span></li>
<li>‍5. Понял, что обучаться нужно на зарубежном рынке и брать оттуда идеи для своего контента.</li>
Писал этот отзыв спустя месяц после консультации. Хочу сказать, что рекомендации Игоря очень сильно помогают. Я наконец-то стал видеть тот результат, к которому никак не мог пробиться. <span>Хорошо, когда есть возможность быстро позвонить вот такому человеку и разобрать вопрос, получить стороннюю оценку.</span>`,
      ],
    },
  },
  {
    user: {
      mobileImage: {
        onex: "/main/user4.jpg",
        twox: "/main/user4.",
        threex: "/main/user4.jpg",
      },
      desktopImage: {
        onex: "/main/user4.jpg",
        twox: "/main/user4.jpg",
        threex: "/main/user4.jpg",
      },
      name: "Инна Соколовская",
      subtitle: "24 года",
      sessionDuration: 15,
      price: 1950,
      result: "Обрела уверенность, что партнёр серьёзно к ней относится",
    },
    expert: {
      id: 20,
      mobileImage: {
        onex: "/main/expert6",
        twox: "/main/expert6",
        threex: "/main/expert6",
      },
      desktopImage: {
        onex: "/main/expert6",
        twox: "/main/expert6",
        threex: "/main/expert6",
      },
      name: "Любовь",
      isFired: true,
      url: "/expert/182",
    },
    request: {
      title: `Запрос`,
      content: [
        `Я живу с молодым человеком уже полгода (встречаемся ещё дольше), и мне кажется, что мне с ним очень повезло: он очень внимательный и заботливый, ценит и любит меня. Но как и у каждой девушки, у меня где-то в глубине души <span>были сомнения — а выбрала ли я правильного человека?</span>`
      ],
    },
    consultation: {
      title: `Консультация`,
      content: [
        `Что хочу сказать: попадание на 100%! Таролог смогла очень точно прочитать наши с Евгением отношения. Сказала, что у моего молодого человека довольно серьёзные ко мне намерения, <span>он решительно хочет быть с мной и готов всё для этого делать и активно действовать, если необходимо.</span> Уверен в себе и во мне — всё так и есть. Мы посмотрели перспективу на ближайший год, как будут развиваться наши отношения.`
      ],
    },
    result: {
      title: `Результат`,
      content: [
        `Вау-эффект в том, что все ссоры, которые между нами случались, были именно из-за той самой пресловутой депрессии и плохого настроения, поэтому могу сказать, что расклад максимально точный.`,
        `Также мне показали, какие детские установки влияют на текущие отношения, почему я так в них сомневаюсь. <span>Что поможет мне чувствовать опору в любви</span>. Спасибо за предупреждение и то, что показали мне, что сомнениям в выборе партнёра всё-таки не место. <span>Я убедилась, что выбрала правильного человека, спасибо!</span>`
      ],
    },
  },

];
